<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-row>
      <v-col>
        <v-sheet class="pa-8 mb-4">
          <h1>{{ $t("location-when") }}</h1>
          <p>
            {{ $t("location-when-text") }}
          </p>
          <v-row>
            <v-col cols="12" sm="6">
              <h2 class="text-center mb-2">{{ $t("location-online") }}</h2>
              <div v-if="groupOnline && groupOnline.status == 200">
                <p class="mb-1 font-weight-bold text-center">
                  {{ $t("location-appointment") }}
                  {{ getWeekday(groupOnline) }}:
                  {{ getStartTime(groupOnline) }} -
                  {{ getEndTime(groupOnline) }}
                </p>
                <p class="font-weight-bold text-center">
                  {{ $t("location-next-meeting") }}
                  {{ getNextMeeting(groupOnline) | formatDate }}
                </p>
              </div>
              <div v-else-if="groupOnline && groupOnline.status != 200">
                <p class="font-weight-bold text-center">
                  {{ $t("communication-error") }}
                </p>
              </div>
              <div v-else>
                <p class="font-weight-bold text-center">
                  {{ $t("location-next-meeting") }}
                  <v-progress-circular indeterminate size="24" class="ml-5" />
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <h2 class="text-center mb-2">{{ $t("location-presence") }}</h2>
              <div v-if="groupPresence && groupPresence.status == 200">
                <p class="mb-1 font-weight-bold text-center">
                  {{ $t("location-appointment-presence") }} 18:00 - 19:30
                </p>
                <p class="font-weight-bold text-center">
                  {{ $t("location-next-meeting") }}
                  {{ getNextMeeting(groupPresence) | formatDate }}
                </p>
              </div>
              <div v-else-if="groupPresence && groupPresence.status != 200">
                <p class="font-weight-bold text-center">
                  {{ $t("communication-error") }}
                </p>
              </div>
              <div v-else>
                <p class="font-weight-bold text-center">
                  {{ $t("location-next-meeting") }}
                  <v-progress-circular indeterminate size="24" class="ml-5" />
                </p>
              </div>
            </v-col>
          </v-row>
          <p>
            {{ $t("location-when-note-1-part-1") }}
            <router-link to="/calendar" class="link">{{
              $t("location-when-note-1-link")
            }}</router-link>
            {{ $t("location-when-note-1-part-2") }}
          </p>
          <p>{{ $t("location-when-note-2") }}</p>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-8 mb-4">
          <h1 class="mb-2">{{ $t("location-where") }}</h1>
          <p class="font-weight-bold mb-1">
            {{ $t("location-address-1") }}
          </p>
          <p class="font-weight-bold mb-1">
            {{ $t("location-address-2") }}
          </p>
          <v-btn
            class="mb-3 link"
            href="https://goo.gl/maps/2YmzwYmCBb2mKkxn8"
            target="_blank"
            text
          >
            <v-icon class="mr-2">mdi-map-marker</v-icon>
            {{ $t("location-map") }}
          </v-btn>
          <p class="mb-1 line-through">
            {{ $t("location-where-note-1-part-1") }}
            <a href="http://zibb-gi.de/" class="link" target="_blank">
              {{ $t("location-where-note-1-link") }}</a
            >{{ $t("location-where-note-1-part-2") }}
          </p>
          <!-- <p>
            {{ $t("location-where-note-2") }}
          </p> -->
          <p>
            {{ $t("location-where-note-3") }}
          </p>
          <v-row justify="center" class="text-center">
            <v-col>
              <v-btn
                large
                color="accent"
                href="../Anleitung_virtueller_Gruppenraum.pdf"
                target="_blank"
              >
                <v-icon class="mr-2">mdi-book-open</v-icon>
                {{ $t("location-read-manual") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn large color="accent" @click.stop="enterDialog = true"
                ><v-icon class="mr-2">mdi-door-open</v-icon
                >{{ $t("location-enter-room") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="enterDialog" max-width="800px">
      <v-card>
        <v-card-title class="headline">{{
          $t("location-dialog-hint")
        }}</v-card-title>
        <v-card-text class="align-justify">
          {{ $t("location-dialog-text") }}
          <a
            href="https://zoom.us/docs/de-de/privacy-and-legal.html"
            class="link"
            >{{ $t("location-dialog-link") }}</a
          >
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" no-gutters>
            <v-btn color="error" text @click="enterDialog = false">
              {{ $t("location-dialog-disagree") }}
            </v-btn>
            <v-btn
              color="success"
              text
              :href="meetingUrl"
              target="_blank"
              @click="enterDialog = false"
            >
              {{ $t("location-dialog-agree") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import moment from "moment";

export default {
  name: "location",
  components: {
    Breadcrumbs
  },
  data: () => ({
    groupOnline: null,
    groupPresence: null,
    meetingUrl:
      "https://zoom.us/j/91975981191?pwd=YmF4SEE4bDFBZnNZVENGZWN0YU96Zz09",
    enterDialog: false,
    manualDialog: false
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("group"),
          disabled: false,
          href: "/group"
        },
        {
          text: this.$t("location"),
          disabled: true
        }
      ];
    }
  },
  mounted() {
    axios
      .get(
        "https://api.bauchgefuehle-giessen.de/api/events/next-group?type=group_online"
      )
      .then(response => (this.groupOnline = response))
      .catch(error => (this.groupOnline = error));

    axios
      .get(
        "https://api.bauchgefuehle-giessen.de/api/events/next-group?type=group_presence"
      )
      .then(response => (this.groupPresence = response))
      .catch(error => (this.groupPresence = error));
  },
  methods: {
    getNextMeeting(response) {
      if (response.data.data.start) {
        return response.data.data.start;
      }
      return this.$t("unknown-date");
    },
    getWeekday(response) {
      let day = moment(String(response.data.data.start)).format("dddd");
      return this.$t(day);
    },
    getStartTime(response) {
      return moment(String(response.data.data.start)).format("HH:mm");
    },
    getEndTime(response) {
      return moment(String(response.data.data.end)).format("HH:mm");
    }
  }
};
</script>
<style scoped>
.link {
  color: #ffc107 !important;
  font-weight: bold;
  text-decoration: none;
}

.line-through,
.line-through > a {
  text-decoration: line-through;
  text-decoration-style: solid;
  text-decoration-color: red !important;
  text-decoration-thickness: 20%;
}

li {
  margin-bottom: 1rem;
  text-align: justify;
}

p {
  text-align: justify;
}

.align-justify {
  text-align: justify;
}
</style>
